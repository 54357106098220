











































import { Component, Vue } from 'vue-property-decorator';
import DeleteCustomInvestorGoalSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/delete-goals/delete-custom-investor-goal-success-view-model';

@Component({ name: 'DeleteCustomInvestorGoalSuccess' })
export default class DeleteCustomInvestorGoalSuccess extends Vue {
  delete_custom_goal_success_model = Vue.observable(new DeleteCustomInvestorGoalSuccessViewModel());
}
