import TYPES from '@/types';

// Application
import DeleteCustomInvestorGoalStateManagerService
  from '@/modules/flagship/custom-investor-goal/application/service/delete-custom-investor-goal-state-manager-service';

// Domain
import DeleteCustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class DeleteCustomInvestorGoalSuccessViewModel {
  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE)
  private readonly state_manager_service!: DeleteCustomInvestorGoalStateManagerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.delete-custom-investor-goal-success';

  state: DeleteCustomInvestorGoalState;

  constructor(state?: DeleteCustomInvestorGoalState) {
    this.state = state || this.state_manager_service.get();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }
}
